import axios from 'axios';
import * as Yup from 'yup';
import {ApiResource} from '../types/ApiResource';
import {ChatChannel} from '../types/ChatChannel';
import {ChatMessage} from '../types/ChatMessage';
import {FileAttachment} from '../types/FileAttachment';
import {PaginatedResponse} from '../types/PaginatedResponse';
import {SuccessResponse} from '../types/SuccessResponse';

export const chatChannelSchema = Yup.object({
  id: Yup.number(),
  client_id: Yup.number().optional(),
  name: Yup.string().max(255, 'Name cannot exceed 250 characters.').required(),
});

type ChatChannelMessagesResource = {
  getDmChannels: () => Promise<ChatChannel[]>;
  getUnreadChannels: () => Promise<ChatChannel[]>;
  findOrCreateDmChannel: (data: {recipients: number[]}) => Promise<ChatChannel>;
  unreadMessages: (chat_channel_id: string | number) => Promise<PaginatedResponse<ChatMessage>>;
  markAsRead: (
    chat_channel_id: string | number,
    patient_id?: string | number,
  ) => Promise<SuccessResponse>;
  getMessage: (chat_message_id: string) => Promise<ChatMessage>;
  getMessages: (
    chat_channel_id?: string,
    patient_id?: string,
  ) => Promise<PaginatedResponse<ChatMessage>>;
  saveMessage: (
    chat_channel_id: string,
    patient_id: string | undefined,
    contents: string,
    text: string,
    attachments: FileAttachment[],
  ) => Promise<ChatMessage>;
};

const chatChannel: ApiResource<ChatChannel> & ChatChannelMessagesResource = {
  all: async () => {
    const reply = await axios.get<ChatChannel[]>(`/api/chat/channel`);
    return reply.data;
  },
  getDmChannels: async () => {
    const reply = await axios.get<ChatChannel[]>(`/api/chat/channel/dm`);
    return reply.data;
  },
  getUnreadChannels: async () => {
    const reply = await axios.get<ChatChannel[]>(`/api/chat/channel/unread`);
    return reply.data;
  },
  findOrCreateDmChannel: async (data) => {
    const reply = await axios.post<ChatChannel>(`/api/chat/channel/dm`, data);
    return reply.data;
  },
  get: async (id) => {
    const reply = await axios.get<ChatChannel>(`/api/chat/channel/${id}`);
    return reply.data;
  },
  create: async (data) => {
    const reply = await axios.post<ChatChannel>(`/api/chat/channel`, data);
    return reply.data;
  },
  update: async (data) => {
    const reply = await axios.put<ChatChannel>(`/api/chat/channel/${data.id}`, data);
    return reply.data;
  },
  delete: async (id: number) => {
    const reply = await axios.delete<SuccessResponse>(`/api/chat/channel/${id}`);
    return reply.data;
  },
  unreadMessages: async (chat_channel_id) => {
    const reply = await axios.get(`/api/chat/channel/${chat_channel_id}/unread`);
    return reply.data;
  },
  markAsRead: async (chat_channel_id, patient_id) => {
    const reply = await axios.post(`/api/chat/channel/${chat_channel_id}/mark-as-read`, {
      patient_id: patient_id ?? null,
    });
    return reply.data;
  },
  getMessage: async (chat_message_id: string) => {
    const reply = await axios.get(`/api/chat/message/${chat_message_id}`);
    return reply.data;
  },
  getMessages: async (chat_channel_id?: string, patient_id?: string) => {
    if (!chat_channel_id && patient_id) {
      const reply = await axios.get(`/api/chat/patient/${patient_id}/messages`);
      return reply.data;
    }

    const reply = await axios.get(
      patient_id
        ? `/api/chat/channel/${chat_channel_id}/patient/${patient_id}/messages`
        : `/api/chat/channel/${chat_channel_id}/messages`,
    );
    return reply.data;
  },
  saveMessage: async (
    chat_channel_id: string,
    patient_id: string | undefined,
    contents,
    text,
    attachments,
  ) => {
    if (!chat_channel_id && patient_id) {
      const reply = await axios.post(`/api/chat/patient/${patient_id}/message`, {
        contents,
        text,
        attachments,
      });
      return reply.data;
    }

    const reply = await axios.post(
      patient_id
        ? `/api/chat/channel/${chat_channel_id}/patient/${patient_id}/message`
        : `/api/chat/channel/${chat_channel_id}/message`,
      {
        contents,
        text,
        attachments,
      },
    );
    return reply.data;
  },
};

export default chatChannel;
